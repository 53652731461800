<template>
  <div class="messages">
    <div class="card recommond-my">
      <div class="text-grey-dark title-md text-bold">
        {{ $t("my_messages") }}
      </div>
      <el-table
        :stripe="true"
        :data="list"
        v-loading="loading"
        style="width: 100%;margin-top:20px"
        element-loading-background="rgba(0, 0, 0, 0)"
      >
        <el-table-column
          :show-overflow-tooltip="true"
          prop="dateline"
          :label="$t('send_time')"
          width="150"
        >
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          prop="title"
          :label="$t('message_title')"
          width="220"
        >
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          prop="content"
          :label="$t('message_content')"
          width="240"
        >
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          prop="read"
          :label="$t('isornot_read')"
          width="180"
        >
          <template slot-scope="scope">
            <el-tag
              v-if="scope.row.read !== '-'"
              :type="getScopType(scope.row.read)"
              effect="dark"
              size="mini"
              >{{ scope.row.read ? $t("is_read") : $t("not_read") }}</el-tag
            >
            <span v-if="scope.row.read === '-'">-</span>
          </template>
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          prop="content"
          :label="$t('actions')"
          width="140"
        >
          <template slot-scope="scope">
            <el-link @click="contentDialogVisible = true">{{
              $t("view")
            }}</el-link>
            <el-dialog
              width="465px"
              append-to-body
              :title="$t('message_content')"
              :visible="contentDialogVisible"
              :before-close="() => (contentDialogVisible = false)"
            >
              <div v-html="scope.row.content"></div>
            </el-dialog>
          </template>
        </el-table-column>
      </el-table>
      <div class="mt-20">
        <el-pagination
          background
          layout="prev, pager, next,total"
          :total="pagination.totalSize"
          :page-size="pagination.pageSize"
          :current-page="pagination.currentPage"
          @current-change="changePage"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<style src="./index.scss" lang="scss"></style>
<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      list: [],
      loading: false,
      pagination: {
        pageSize: 15,
        totalSize: 0,
        currentPage: 1
      },
      contentDialogVisible: false
    };
  },
  async created() {
    this.getList();
  },
  methods: {
    changePage(v) {
      this.getList(v);
    },
    async getList(v) {
      this.loading = true;
      const res = await this.$http.get(
        `/api/v1/Accounts/${this.account.id}/Messages`,
        {
          params: {
            page: v || this.pagination.currentPage,
            size: this.pagination.pageSize
          }
        }
      );
      this.list = res.data.list;
      this.pagination = res.data.pagebar;
      this.loading = false;
    },
    getScopType(status) {
      return status ? "success" : "info";
    }
  },
  computed: {
    ...mapGetters(["account"])
  }
};
</script>
